// dateUtils.ts
import { format, parseISO } from 'date-fns';

// Define a type for allowed date formats
type DateFormat = 'MM/dd/yy' | 'yyyy-MM-dd' | 'dd/MM/yyyy' | 'MMM dd, yyyy';

/**
 * Formats a given date string into the specified format.
 * @param dateString - The date string to format.
 * @param dateFormat - The format to apply to the date string.
 * @returns The formatted date string.
 */
export function formatDate(dateString: string, dateFormat: DateFormat): string {
    if (!dateString) {
        return '';
    }
    
    const date = parseISO(dateString);
    return format(date, dateFormat);
}

export default formatDate;

import { addMonths as addMonthsFn } from 'date-fns';
import { formatDate } from '../../../../utils';

export const addMonths = (datetimeString: string, monthsToAdd: number) => {
  const date = new Date(datetimeString); // Parse the input datetime string to a Date object
  const newMonth = addMonthsFn(date, monthsToAdd).toISOString(); // Add months and return as ISO string
  const newFormattedMonth = formatDate(newMonth, 'MMM dd, yyyy');
  return newFormattedMonth;
  // return addMonthsFn(date, monthsToAdd).toISOString(); // Add months and return as ISO string
};

export default addMonths;

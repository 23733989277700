import React from 'react';
import { Box } from '@mui/material';

import { productImages } from '../../../../elo.config';

interface ProductImageProps {
  id: string;
  slug?: string;
  alt?: string;
}

const getProductImageUrl = (id: string) => {
  return productImages[id];
};

const getProductImageUrlBySlug = (slug: string) => {
  return productImages[slug];
};

const ProductImage = ({ id, alt, slug }: ProductImageProps) => {
  if (!id || !slug) return null;
  if (!productImages) return null;

  return (
    <>
      <Box
        component={'img'}        
        src={getProductImageUrlBySlug(slug)}
        alt={`${alt}`}
        sx={{
          maxWidth: '100%',
          maxHeight: ['240px', '240px', '380px'],
          objectFit: 'contain',
          width: 'auto',
          height: 'auto',
        }}
      />
    </>
  );
};

export default ProductImage;

import React, { useState } from 'react';
import { Box, Stack, Typography, Button, Divider, CircularProgress } from '@mui/material';
import { SubscriptionStatus } from 'src/API';
import { pauseSubscription } from '../../../../actions';
import { UserSubscription } from '../../../../types/API';
import { refreshAccountStore } from '../../../../data';
import SetPauseOptions from './SetPauseOptions';
import utilAddMonths from './utilAddMonths';

interface CancelModalProps {
  openModal: (value: boolean) => void;
  subscriptionId: string;
  subscription: UserSubscription;
  value: SubscriptionStatus | number;
  setValue: (value: SubscriptionStatus | number | undefined | null) => void;
}

const pauseOption = ({
  openModal,
  subscriptionId,
  subscription,
  value,
  setValue,
}: CancelModalProps) => {
  console.log('value', value);

  const [cancelReason, setCancelReason] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const handlePauseSubscription = async () => {
    setLoading(true);
    // * Cancel Subscription
    const res = await pauseSubscription({
      subscriptionId,
      months: value as number,
    });

    // * Refresh Account Store
    await refreshAccountStore();

    setLoading(false);

    if (res?.errors) {
      setError(true);
    } else {
      setSuccess(true);
    }
  };

  return (
    <>
      <Stack spacing={2} py={['1rem']} flexGrow={1} justifyContent={'space-between'}>
        {!success && (
          <>
            <Typography variant="h4" textAlign={'center'}>
              Pause Subscription
            </Typography>
            <Divider />
            <Stack
              spacing={2}
              display={'flex'}
              justifyContent={'center'}
              p={['.47rem']}
              flexDirection={'column'}
            >
              <Typography variant="body2">
                Your next order will be delayed by one month. It will be scheduled to ship on{' '}
                {utilAddMonths(subscription.periodEndAt ?? '', parseInt(value as string))}.
              </Typography>
              <Typography variant="body2">
                You may still be charged for pending payments.
              </Typography>
            </Stack>

            {/**********************************************************************
             * PAUSE OPTIONS
             * ********************************************************************/}
            <Box py={2} pb={6} px={3}>
              <SetPauseOptions
                subscription={subscription}
                value={value as number}
                setValue={setValue}
              />
            </Box>
          </>
        )}

        {loading && (
          <Box py={['1.47rem']} width={'100%'} display={'flex'} justifyContent={'center'}>
            {loading && loadingScreen()}
          </Box>
        )}

        {success && (
          <>
            <Typography variant="h5" textAlign={'center'}>
              Your next order has been delayed.
            </Typography>
            <Typography variant="body2">
              Your next order is scheduled to ship on{' '}
              {utilAddMonths(subscription.pausedUntil ?? '', 0)}.
            </Typography>
            <Typography variant="body2">You may still be charged for pending payments.</Typography>
            <Button variant="contained" onClick={() => openModal(false)}>
              Ok
            </Button>
          </>
        )}

        {error && (
          <>
            <Typography variant="body2" color={'success'} textAlign={'center'}>
              There was a problem pausing your subscription
            </Typography>
            <Typography variant="body2" color={'success'} textAlign={'center'}>
              <a href="mailto:help@luminaryvitamins.com">Please contact support</a>
            </Typography>
          </>
        )}

        {!loading && !error && !success && (
          <Stack spacing={2}>
            <Button variant="contained" onClick={handlePauseSubscription} disabled={loading}>
              Delay next order
            </Button>
            {!loading && (
              <Button variant="outlined" onClick={() => openModal(false)}>
                Cancel
              </Button>
            )}
          </Stack>
        )}
      </Stack>
    </>
  );
};

const loadingScreen = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'rgba(255, 255, 255, 1)',
      }}
    >
      <Stack spacing={2} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
        <Typography variant="h4" gutterBottom textAlign={'center'}>
          Please Wait...
        </Typography>
        <CircularProgress />
        <Typography variant="body1" textAlign={'center'}>
          We are processing your request
        </Typography>
      </Stack>
    </Box>
  );
};

export default pauseOption;

import React from 'react';
import { AccountSettings } from '@aws-amplify/ui-react';
import { Accordian } from 'src/elo/apps/account/components';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

const ChangePassword = () => {
  const handleSuccess = () => {
    alert('Your password has been updated.');
  };

  return (
    <Accordian title="Change Password" expanded={false} variant='h4'>
        <AccountSettings.ChangePassword onSuccess={handleSuccess} />
    </Accordian>
  );
};

export default ChangePassword;

import React, { useState } from 'react';
import {
  SubscriptionStatus as ENUMSubscriptionStatus,
  UserSubscription,
} from '../../../../types/API';
import {
  Box,
  Typography,
  Button,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
  Modal,
} from '@mui/material';
import StatusModal from './StatusModal';

import { formatDate } from '../../../../utils';

interface SubscriptionStatusProps {
  subscription: UserSubscription;
}

const SubscriptionStatus = ({ subscription }: SubscriptionStatusProps) => {
  const [value, setValue] = useState<ENUMSubscriptionStatus | number | undefined | null>(
    subscription.status
  );
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value as ENUMSubscriptionStatus);
  };

  const handleCancleSubscription = () => {
    setCancelModalOpen(true);
    if (value === ENUMSubscriptionStatus.canceled) {
      setCancelModalOpen(true);
    }
  };

  if (cancelModalOpen) {
    return (
      <>
        <Modal open={cancelModalOpen} onClose={() => setCancelModalOpen(false)}>
          <StatusModal
            openModal={setCancelModalOpen}
            subscriptionId={subscription.id}
            subscription={subscription}
            value={value ?? ENUMSubscriptionStatus.canceled}
            setValue={setValue}
          />
        </Modal>
      </>
    );
  }

  if (subscription.cancellationRequestedAt) {
    return (
      <>
        <Box>
          <Typography variant="body2" sx={{ marginTop: 1 }}>
            Your subscription has been canceled. You may still be billed for the remainder of your
            subscription period, which ends on{' '}
            {subscription.committedUntil && formatDate(subscription.committedUntil, 'MMM dd, yyyy')}
          </Typography>
        </Box>
      </>
    );
  }

  if (subscription.pausedUntil) {
    return (
      <>
        <Box>
          <Typography variant="body2" sx={{ marginTop: 1 }}>
            Your subscription has been paused.
          </Typography>
          {subscription.committedUntil && (
            <Typography variant="body2" sx={{ marginTop: 1 }}>
              Your next shipment is scheduled for{' '}
              {subscription.committedUntil &&
                formatDate(subscription.committedUntil, 'MMM dd, yyyy')}
              .
            </Typography>
          )}
        </Box>
      </>
    );
  }

  return (
    <>
      <Box>
        <Stack spacing={2}>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <Stack spacing={1}>
              <FormControlLabel
                value={ENUMSubscriptionStatus.active}
                control={<Radio />}
                label={
                  <Typography variant="body2" sx={{ marginTop: 1 }}>
                    Active
                  </Typography>
                }
                sx={{ alignItems: 'flex-start' }} // Align items to start at the top
              />

              {/************************************************************
               * PAUSE SUBSCRIPTION OPTIONS
               * -----------------------------------------------------------
               * This option selections allow the user to pause their
               * subscription for a period of time.
               * **********************************************************/}

              <FormControlLabel
                value={1}
                control={<Radio />}
                label={
                  <>
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                      Delay next order
                    </Typography>
                    <Typography variant="subtitle2" sx={{ marginTop: 1 }}>
                      {/* Ships on {addMonths(subscription.periodEndAt ?? '', 1)} */}
                      Delay your next order for up to 3 months.
                    </Typography>
                  </>
                }
                sx={{ alignItems: 'flex-start' }} // Align items to start at the top
              />

              {/************************************************************
               * CANCEL SUBSCRIPTION
               * -----------------------------------------------------------
               * This option allows the user to cancel their subscription.
               * **********************************************************/}
              <FormControlLabel
                value={ENUMSubscriptionStatus.canceled}
                control={<Radio />}
                label={
                  <>
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                      Cancel my subscription
                    </Typography>
                    <Typography variant="subtitle2" sx={{ marginTop: 1 }}>
                      You may still be billed for a balance due on the last order that was prepared
                      or shipped.
                    </Typography>
                  </>
                }
                sx={{ alignItems: 'flex-start' }} // Align items to start at the top
              />
            </Stack>
          </RadioGroup>

          <Box pt={['1.47rem']}>
            <Button variant="contained" color="primary" onClick={handleCancleSubscription}>
              Update
            </Button>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default SubscriptionStatus;

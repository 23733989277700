import React, { useState } from 'react';
import { Button, Drawer, IconButton, Box, Divider, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { navigate } from 'gatsby';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { useAccountStore } from '../../../../store';

const AccountDrawer = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const firstName = useAccountStore(state => state.userAccount?.firstName);
  const lastName = useAccountStore(state => state.userAccount?.lastName);
  const email = useAccountStore(state => state.userAccount?.email);
  const updateAccountState = useAccountStore(state => state.updateAccountState);

  /**********************************************************************
   * * DESTRUCTURE THE USER AND SIGNOUT VALUES FROM THE AUTHENTICATOR
   * ********************************************************************/
  const { user, signOut } = useAuthenticator(context => [context.user]);

  const handleNavigate = () => {
    setOpen(false);
    navigate('/account');
  };

  /**********************************************************************
   * * HANDLE SIGN OUT
   * --------------------------------------------------------------------
   * This function handles the sign out event.
   * I think that by signing out that the auth listener will catch the
   * signed out event and trigger the necessary actions.
   * ********************************************************************/
  const handleSignOut = () => {    
    // setOpen(false);
    // updateAccountState(state => {
    //   state.showLoginModal = false;
    // });    
    signOut();
    // navigate('/');
  };

  return (
    <>
      <IconButton
        onClick={toggleDrawer(true)}
        sx={{
          padding: 'unset !important',
          marginRight: '-1rem',
        }}
      >
        {/* <AccountCircleIcon sx={{ color: 'orange' }} fontSize="large" /> */}
        <AccountCircleIcon sx={{ color: 'orange' }} fontSize="large" />
      </IconButton>

      <Drawer open={open} anchor="right" onClose={toggleDrawer(false)}>
        <Box
          sx={{
            minWidth: ['60vw', '40vw', '30vw', '20vw'],
          }}
        >
          <Box p="1.47rem" display={'flex'} justifyContent={'space-between'}>
            <Box>
              <Typography variant="body1">
                {firstName} {lastName}
              </Typography>
              <Typography variant="body2">{email}</Typography>
            </Box>
            <Box>
              <IconButton onClick={toggleDrawer(false)}>
                <CancelIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box p="1.47rem" display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
            <Button onClick={handleNavigate}>Manage Account</Button>
            <Button onClick={handleSignOut}>Logout</Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default AccountDrawer;

/********************************************************************************
 * ACTION: CANCEL SUBSCRIPTION
 * ----------------------------------------------------------------------------
 * This action executes a mutation to cancel the user's subscription to a product.
 * *****************************************************************************/

/**********************************************************************
 * * GENERATE API CLIENT
 * ********************************************************************/
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

/**********************************************************************
 * * IMPORT GRAPHQL RESULT AND GRAPHQL OPTIONS TYPES
 * ********************************************************************/
// import { cancelSubscription as cancelSubscriptionQuery } from '../data/graphql/mutations';
import { pauseSubscription as pauseSubscriptionMutation } from 'src/graphql/mutations';
import { PauseSubscriptionInput, PauseSubscriptionResult } from 'src/API';
import { GraphQLResult, GraphQLOptions } from '@aws-amplify/api-graphql';

/******************************************************************************************
 * * CANCEL SUBSCRIPTION FUNCTION
 * ---------------------------------------------------------------------------------------
 * ***************************************************************************************/

const pauseSubscription = async (input: PauseSubscriptionInput) => {
  // * BUILD CANCEL SUBSCRIPTION MUTATION
  const buildMutation = (): GraphQLOptions => {
    const mutation = pauseSubscriptionMutation;
    const authMode = 'userPool';

    // Convert input.months to a number if it comes as a string
    let months = Number(input.months);

    // Validate that months is a valid number
    if (isNaN(months) || months < 0) {
      throw new Error(
        `Invalid input: 'months' must be a non-negative number. Received: ${input.months}`
      );
    }

    const variables: { input: PauseSubscriptionInput } = {
      input: {
        subscriptionId: input.subscriptionId,
        months: input.months,
      },
    };
    return {
      query: mutation,
      authMode,
      variables,
    };
  };

  // * CALL THE MUTATION
  try {
    const mutation = buildMutation();
    const result = (await client.graphql(mutation)) as GraphQLResult<PauseSubscriptionResult>;

    if (result.data) {
      return result;
    } else {
      throw new Error(result.errors?.[0].message);
    }
  } catch (error) {
    console.error('Error pausing subscription: ', error);
    return { errors: [error] };
  }
};

export default pauseSubscription;

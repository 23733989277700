/******************************************************************************************
 * * SUBSCRIPTIONS
 * ---------------------------------------------------------------------------------------
 * The subscriptions component displays the current subscriptions for the user and allows
 * them to update their subscriptions. It will likely consist of several different
 * components that are used to display the different types of subscriptions the user has.
 * ***************************************************************************************/

import React from 'react';
// import { pregnancyStage } from '../../elo.config';
import { Box, Typography, Divider, Stack, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { formatLabel, formatDate, dateMinusDays } from '../../utils';
// import { GummyFlavor } from '../../types/API';
import { formatCurrency } from '../../utils';
import { ProductImage, SubscriptionStatus } from './components';
import { Accordian } from '../Components';
import CircleIcon from '@mui/icons-material/Circle';
import { SubscriptionStage, SubscriptionFlavor } from './components';

/******************************************************************************************
 * * IMPORT ACCOUNT STORE
 * ---------------------------------------------------------------------------------------
 * ***************************************************************************************/
import { useAccountStore } from '../../store';

/******************************************************************************************
 * * IMPORT TYPES
 * ---------------------------------------------------------------------------------------
 * ***************************************************************************************/
import { UserSubscription } from '../../types/API';
import { sub } from 'date-fns';
import { format } from 'path';

export const UserSubscriptions = () => {
  /******************************************************************************************
   * * SUBSCRIBE TO DATA IN THE ELO ACCOUNT STORE
   * ---------------------------------------------------------------------------------------
   * Only subscribe to the data you need. In this case, the subscriptions from the userAccount.
   * ***************************************************************************************/
  // * State
  const userAccount = useAccountStore(state => state.userAccount);
  const subscriptions = useAccountStore(state => state.userAccount?.subscriptions);

  if (!subscriptions) return null;

  let items = [...subscriptions.items];
  /******************************************************************************************
   * * PENDING SUBSCRIPTIONS
   * ---------------------------------------------------------------------------------------
   * When a user first signs migrates from preconcpetion to pregnancy, there will be a
   * delay before the actual subscription items are returned. This is because it takes a
   * time for the user's account to be updated in the ELO cloud. There for, we need to
   * display some kind of placeholder until the actual subscription items are returned.
   *
   * The indicator is
   * Pregnancy Stage is not preconception && subscriptions.items.length === 1
   * ***************************************************************************************/

  const stage = userAccount?.preferences?.pregnancyStage;
  const itemNumber = subscriptions.items.length;

  const getStatus = (subscription: UserSubscription) => {
    if (subscription.cancellationRequestedAt) return 'Cancellation Pending';
    if (subscription.pausedUntil) return 'Paused';
    return formatLabel(subscription.status);
  };

  const getStatusColor = (subscription: UserSubscription) => {
    if (subscription.cancellationRequestedAt) return '#f69b32';
    if (subscription.pausedUntil) return '#f69b32';
    return 'green';
  };

  /******************************************************************************************
   * * GET STAGE
   * ---------------------------------------------------------------------------------------
   * Get the stage of the subscription. This is used to display the stage of the pregnancy
   * the user is in. It is based on the product stage and phase.
   * ***************************************************************************************/
  const getStage = (subscription: UserSubscription) => {
    let slug;
    if (subscription.purchasedSubscription?.phases) {
      slug = subscription.purchasedSubscription?.phases[0]?.slug;
    } else {
      slug = subscription.purchasedSubscription?.slug;
    }

    let stage;
    switch (slug) {
      case 'create':
        stage = 'Preconception';
        break;
      case 'form':
        stage = 'Early pregancy';
        break;
      case 'flourish':
        stage = 'Late pregnancy';
        break;
      case 'nurture':
        stage = 'Postpartum';
        break;
      default:
        stage = '';
        break;
    }
    return stage;
  };

  /******************************************************************************************
   * * GET PHASE
   * ---------------------------------------------------------------------------------------
   * Get the phase of the subscription. This is used to display the correct state image for
   * the subscription. It is based on the product stage and phase.
   * ***************************************************************************************/
  const getPhase = (subscription: UserSubscription) => {
    let phase;
    if (subscription.purchasedSubscription?.phases) {
      phase = subscription.purchasedSubscription?.phases[0]?.slug;
    } else {
      phase = subscription.purchasedSubscription?.slug;
    }
    return phase;
  };

  /******************************************************************************************
   * * GET TITLE
   * ---------------------------------------------------------------------------------------
   * The title has some complex logic to determine the correct title to display. It is based
   * the product as well as the phase of a specific product. The title returned by the API
   * only returns the product title but the title that we display to the user needs to also
   * reflect the phase of the product. Therefore, we need to determine the correct title to
   * display based on the product and phase.
   * ***************************************************************************************/
  const getTitle = (subscription: UserSubscription) => {
    let title = 'create';

    if (subscription.purchasedSubscription?.phases) {
      title = subscription.purchasedSubscription?.phases[0]?.slug;
      switch (title) {
        case 'create':
          title = 'Luminary Vitamins Create';
          break;
        case 'form':
          title = 'Luminary Vitamins Form';
          break;
        case 'flourish':
          title = 'Luminary Vitamins Flourish';
          break;
        case 'nurture':
          title = 'Luminary Vitamins Nurture';
          break;
        case 'thrive':
          title = 'Luminary Vitamins Thrive';
          break;
        case 'balance':
          title = 'Luminary Vitamins Balance';
          break;
        case 'create-for-men':
          title = 'Create for Men';
          break;
        case 'thrive-for-men':
          title = 'Thrive for Men';
          break;
        default:
          title = '';
          break;
      }
    }
    return title;
  };

  return (
    <>
      <Stack spacing={4}>
        {items.map((subscription, index) => {
          // * RETURN NULL IF THERE IS NO SUBSCRIPTION
          if (!subscription) return null;

          return (
            <>
              <Grid
                container
                spacing={2}
                sx={{
                  background: '#F8F9F2',
                  borderRadius: '8px',
                  border: '2px solid #E5E5E5',
                  p: 1,
                }}
                key={index}
              >
                {/********************************************************************************
                 * PRODUCT IMAGE
                 * -------------------------------------------------------------------------------
                 * ******************************************************************************/}
                <Grid
                  size={{ xs: 12, md: 6 }}
                  sx={{
                    borderRight: ['unset', 'unset', '1px solid #E1E2D2'],
                  }}
                >
                  <Grid container>
                    <Grid size={{ xs: 6, md: 12 }}>
                      <Box
                        sx={{
                          p: [0, 0, 2, 4],
                          overflow: 'hidden',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <ProductImage
                          id={subscription.purchasableSubscriptionId}
                          alt={subscription.title ?? 'Product Image'}
                          slug={getPhase(subscription)}
                        />
                      </Box>
                    </Grid>
                    <Grid size={{ xs: 6 }}>
                      {/********************************************************************************
                       * PRODUCT DETAILS MOBILE
                       * -------------------------------------------------------------------------------
                       * - Product Name
                       * - Price
                       * - Next Bill Date
                       * ******************************************************************************/}
                      <Stack
                        spacing={3}
                        sx={{
                          display: ['block', 'block', 'none'],
                          pt: 2,
                          pr: 2,
                        }}
                      >
                        <Typography variant="body2">{getTitle(subscription)}</Typography>
                        <Box display={'flex'} justifyContent={'space-between'}>
                          <Typography variant={'subtitle2'} fontWeight={700}>
                            Price
                          </Typography>
                          <Typography variant="subtitle2">
                            {formatCurrency(
                              subscription.invoices?.items[0]?.total ?? (69 as number)
                            )}{' '}
                            / mo
                          </Typography>
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'}>
                          <Typography variant={'subtitle2'} fontWeight={700}>
                            {subscription.cancellationRequestedAt
                              ? 'Final bill date'
                              : 'Next bill date'}
                          </Typography>
                          {subscription.cancellationRequestedAt ? (
                            <>
                              <Typography variant="subtitle2">
                                {!subscription.upcomingInvoices
                                  ? ''
                                  : formatDate(
                                      dateMinusDays(subscription.committedUntil as string, 30),
                                      'MMM dd, yyyy'
                                    )}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography variant="subtitle2">
                                {!subscription.upcomingInvoices
                                  ? 'Cancelled'
                                  : formatDate(
                                      subscription.upcomingInvoices?.[0]?.dueAt ?? '',
                                      'MMM dd, yyyy'
                                    )}
                              </Typography>
                            </>
                          )}
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid size={{ xs: 12, md: 6 }} p={'1rem'}>
                  {/********************************************************************************
                   * PRODUCT DETAILS DESKTOP
                   * -------------------------------------------------------------------------------
                   * - Product Name
                   * - Price
                   * - Next Bill Date
                   * ******************************************************************************/}
                  <Grid container spacing={2} pb={4} sx={{ display: ['none', 'none', 'flex'] }}>
                    {/* PRODUCT TITLE */}
                    <Grid size={{ xs: 12 }}>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {getTitle(subscription)}
                      </Typography>
                    </Grid>

                    {/* PRODUCT PRICE */}
                    <Grid size={{ xs: 6, sm: 4, md: 6, lg: 4 }}>
                      <Typography variant="body2" sx={{ mr: 1, fontWeight: 'bold' }}>
                        Price
                      </Typography>
                    </Grid>
                    <Grid size={{ xs: 6, md: 6 }}>
                      <Typography variant="body2">
                        {formatCurrency(subscription.invoices?.items[0]?.total ?? (69 as number))} /
                        mo
                      </Typography>
                    </Grid>

                    {/* NEXT OR FINAL BILL DATE */}
                    <Grid size={{ xs: 6, sm: 4, md: 6, lg: 4 }}>
                      <Typography variant="body2" sx={{ mr: 1, fontWeight: 'bold' }}>
                        {subscription.cancellationRequestedAt
                          ? 'Final bill date'
                          : 'Next bill date'}
                      </Typography>
                    </Grid>
                    <Grid size={{ xs: 6 }}>
                      {subscription.cancellationRequestedAt ? (
                        <>
                          <Typography variant="body2">
                            {!subscription.upcomingInvoices
                              ? ''
                              : formatDate(
                                  dateMinusDays(subscription.committedUntil as string, 30),
                                  'MMM dd, yyyy'
                                )}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography variant="body2">
                            {!subscription.upcomingInvoices
                              ? 'Cancelled'
                              : formatDate(
                                  subscription.upcomingInvoices?.[0]?.dueAt ?? '',
                                  'MMM dd, yyyy'
                                )}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>

                  <Divider />

                  {/********************************************************************************
                   * STAGE
                   * -------------------------------------------------------------------------------
                   * Don't show the stage under the following conditions:
                   * - The subscription is not a create subscription
                   * - The subscription has a cancellation request
                   * ******************************************************************************/}
                  {subscription.purchasedSubscription?.slug === 'create' &&
                  !subscription.cancellationRequestedAt ? (
                    <>
                      <Accordian
                        expanded={false}
                        summary={
                          <>
                            <Box
                              display={'flex'}
                              justifyContent={'space-between'}
                              py={'1rem'}
                              flexGrow={1}
                            >
                              <Stack spacing={1}>
                                <Typography variant="body1">Stage</Typography>
                                <Typography variant="body2" color="#ABABAB">
                                  {getStage(subscription)}
                                </Typography>
                              </Stack>
                              <Box>
                                <Button
                                  variant="outlined"
                                  size={'small'}
                                  sx={{ background: 'white', color: '#191919', px: '1rem' }}
                                >
                                  Update
                                </Button>
                              </Box>
                            </Box>
                          </>
                        }
                      >
                        <SubscriptionStage />
                      </Accordian>

                      <Divider />
                    </>
                  ) : null}

                  {/********************************************************************************
                   * FLAVOR
                   * -------------------------------------------------------------------------------
                   * ******************************************************************************/}
                  <SubscriptionFlavor subscription={subscription} />

                  <Divider />

                  {/********************************************************************************
                   * STATUS
                   * -------------------------------------------------------------------------------
                   * ******************************************************************************/}
                  <Accordian
                    // variant="body2"
                    expanded={false}
                    summary={
                      <Box
                        display={'flex'}
                        flexGrow={1}
                        justifyContent={'space-between'}
                        py={'1rem'}
                      >
                        <Stack spacing={1}>
                          <Typography variant="body1">Status</Typography>
                          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <CircleIcon
                              sx={{
                                color: getStatusColor(subscription),
                                fontSize: 'medium',
                                mr: 1,
                              }}
                            />
                            <Typography variant="body2" color="#ABABAB">
                              {getStatus(subscription)}
                            </Typography>
                          </Box>
                        </Stack>
                        <Box>
                          <Button
                            variant="outlined"
                            size={'small'}
                            sx={{ background: 'white', color: '#191919', px: '1rem' }}
                          >
                            {subscription.cancellationRequestedAt || subscription.pausedUntil
                              ? 'Details'
                              : 'Update'}
                          </Button>
                        </Box>
                      </Box>
                    }
                  >
                    {/**********************************************************************
                     * * MANAGE SUBSCRIPTION
                     * ---------------------------------------------------------------------
                     * This section allows the user to manage their subscription. They can
                     * pause, cancel, or update their subscription.
                     * **********************************************************************/}
                    <Box pb={['.47rem']}>
                      <SubscriptionStatus subscription={subscription} />
                    </Box>
                  </Accordian>
                </Grid>
              </Grid>
            </>
          );
        })}
      </Stack>
    </>
  );
};

export default UserSubscriptions;

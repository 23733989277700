import React from 'react';
import { Stack, Paper, Box, Typography, Button, CircularProgress } from '@mui/material';
import { UserSubscription, GummyFlavor } from 'src/API';
import { formatDate, formatGummyFlavor } from 'src/elo/utils';

export enum FlavorModalStatus {
  UPDATING = 'UPDATING', // Status when the flavor is being updated
  INITIALIZING = 'INITIALIZING', // Status when the user account is being re-initialized
  SUCCESS = 'SUCCESS', // Status when the flavor has been updated successfully
  ERROR = 'ERROR', // Status when there was an error updating the flavor
}

interface FlavorModalProps {
  onClose: () => void;
  flavor: GummyFlavor;
  status: FlavorModalStatus | null;
  subscription: UserSubscription;
}

const FlavorModal = ({ onClose, status, subscription, flavor }: FlavorModalProps) => {
  return (
    <>
      {/* Backdrop */}
      <Box
        sx={{
          position: 'fixed', // Make the backdrop cover the entire viewport
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.2)', // Semi-transparent background
          zIndex: 9999, // Ensure it's above other components
        }}
      >
        {/* Modal content */}
        <Stack
          spacing={6}
          p={4}
          component={Paper}
          elevation={3}
          width={['90%','420px']}
          minHeight={'420px'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            position: 'relative', // Keep it relative to the parent
            zIndex: 10000, // Ensure it's above the backdrop
          }}
        >
          {status === FlavorModalStatus.UPDATING && (
            <>
              <Typography variant="h3">Updating Flavor</Typography>
              <CircularProgress sx={{ color: '#F69B32' }} />
              <Typography variant="h6">Please Wait</Typography>
            </>
          )}
          {status === FlavorModalStatus.INITIALIZING && (
            <>
              <Typography variant="h3">Wrapping Up</Typography>
              <CircularProgress sx={{ color: '#F69B32' }} />
              <Typography variant="h6">Just confirming everything is done!</Typography>
            </>
          )}
          {status === FlavorModalStatus.ERROR && (
            <>
              <Typography variant="h3">Ooops!</Typography>
              <Typography variant="h6">
                Well, this is embarrasing, we seem to have run into a problem.
              </Typography>
              <Typography variant="h6">
                Please try again and if the problem persists, contact customer support.
              </Typography>
              <Button variant="contained" onClick={onClose}>
                Try Again
              </Button>
            </>
          )}
          {status === FlavorModalStatus.SUCCESS && (
            <>
              <Typography variant="h3">Flavor updated</Typography>
              <Typography variant="body1">
                Your next order, shipping on{' '}
                {formatDate(subscription?.periodEndAt ?? '', 'MMM dd, yyyy')}, will now contain the{' '}
                {formatGummyFlavor(flavor).toLowerCase()} flavor.
              </Typography>
              <Button variant="contained" onClick={onClose} sx={{ px: 10 }}>
                Close
              </Button>
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default FlavorModal;

/********************************************************************************
 * * INITIAL STATE
 * ------------------------------------------------------------------------------
 * I'm setting the inital state here so that it can be pread into the create
 * create store function below. It's used in two places so I'm keeping it DRY.
********************************************************************************/

import { State } from './StoreV2.types';

export const initialState: State = {    
    layout: {
        contentSpacing: ['10px'],
        sectionLayout: {
            margin: {
                m: [''],
                mx: [''],
                my: [''],
                mt: [''],
                mr: [''],
                mb: [''],
                ml: ['']
            },
            padding: {
                p: [''],
                px: [''],
                py: [''],
                pt: [''],
                pr: [''],
                pb: [''],
                pl: ['']
            }
        },
        blockLayout: {
            margin: {
                m: [''],
                mx: [''],
                my: [''],
                mt: [''],
                mr: [''],
                mb: [''],
                ml: ['']
            },
            padding: {
                p: [''],
                px: [''],
                py: [''],
                pt: [''],
                pr: [''],
                pb: [''],
                pl: ['']
            }
        }
    },
    purchaseLineItemVisible: false,
    loadingLineItems: false,    
    activeClinic: null,
    activeLineItem: undefined,
    activeLineItemPrice: undefined,
}

import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { UserSubscriptions, UserInvoices } from '../../../../ui/';
import { refreshAccountStore } from '../../../../data/refreshAccountStore';

import { useAccountStore } from '../../../../store';

const Subscriptions = () => {
  /****************************************************************************************
   * * REFRESH ACCOUNT STORE
   * ---------------------------------------------------------------------------------------
   * Sometimes we need to refresh the account store. This function is used to refresh the
   * account data if a user is logged in but no account data is found in the account store.
   * This ensures the function only runs up to 5 times to avoid race conditions.
   * ***************************************************************************************/
  const userAccount = useAccountStore(state => state.userAccount);
  const refreshAttempts = useRef(0); // Counter to track refresh attempts

  useEffect(() => {
    const loadData = async () => {
      // Check if the refresh attempts are below the limit of 5
      if (refreshAttempts.current < 5) {
        refreshAttempts.current += 1; // Increment the counter
        await refreshAccountStore(); // Refresh the account store
      }
    };

    // Trigger the refresh only if userAccount is not available
    if (!userAccount) {
      loadData();
    }
  }, [userAccount]);

  return (
    <>
      <Grid container spacing={1}>
        {/* User Subscriptions */}
        <Grid size={{ xs: 12 }}>
          <Box mx={['.47rem']} my={['1rem']}>
            <UserSubscriptions />
          </Box>
        </Grid>

        {/* User Invoices */}
        <Grid size={{ xs: 12 }}>
          <Box mx={['.47rem']} my={['1rem']}>
            <UserInvoices />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Subscriptions;

import React, { useEffect, useState } from 'react';
import { UserSubscription, GummyFlavor, SubscriptionStatus } from 'src/API';
import { Accordian } from 'src/elo/ui/Components';
import {
  Stack,
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from '@mui/material';
import { formatGummyFlavor } from 'src/elo/utils';
// import { GummyFlavor } from 'src/elo/types/API';
import { useAccountStore } from 'src/elo/store';
import { updateFlavor } from 'src/elo/actions';
import FlavorModal from './FlavorModal';
import { getUserAccount } from 'src/elo/data';
import { FlavorModalStatus } from './FlavorModal';

interface SubscriptionFlavorProps {
  subscription: UserSubscription;
}

const SubscriptionFlavor = ({ subscription }: SubscriptionFlavorProps) => {
  const updateAccountState = useAccountStore(state => state.updateAccountState);

  const lineItems = useAccountStore(state => state.lineItems);
  const [flavors, setFlavors] = useState<(GummyFlavor | null)[]>([]);
  const [flavor, setFlavor] = useState<GummyFlavor | null>(
    subscription?.preferences?.gummyFlavor ?? null
  );

  const [loading, setLoading] = useState(false);
  const [modalStatus, setModalStatus] = useState<FlavorModalStatus | null>(
    FlavorModalStatus.UPDATING
  );

  /********************************************************************************
   * AVAILABLE FLAVORS
   * ----------------------------------------------------------------------------
   * We need to get the available flavors from from the line items. This means we
   * need to match the current subscription line item with the available flavors
   * found in the line items. This data is stored in the global context. It's loaded
   * when the user logs in.
   *
   * Use the purchasableSubscriptionId to find the line item that matches the
   * subscription.
   * ******************************************************************************/
  useEffect(() => {
    if (lineItems) {
      const item = lineItems.find(
        lineItem => lineItem.purchasableSubscriptionId === subscription?.purchasableSubscriptionId
      );

      setFlavors(item?.flavors ?? []);
    }
  }, [lineItems, subscription]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFlavor((event.target as HTMLInputElement).value as GummyFlavor);
  };

  const handleClose = () => {
    setLoading(false);
  };

  const handleUpdateFlavor = async () => {
    // Exit if there is not subscription id or flavor
    if (!subscription?.id || !flavor) {
      setModalStatus(null);
      return;
    }

    // Set Loading to True
    // This will show the loading modal
    setLoading(true);
    setModalStatus(FlavorModalStatus.UPDATING);

    try {
      // Update the flavor
      await updateFlavor({
        subscriptionId: subscription?.id ?? '',
        preferences: {
          gummyFlavor: flavor,
        },
      });

      // Pause for a moment
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Re Initialize the user account data
      // Neet to set the modal status to INITIALIZING
      setModalStatus(FlavorModalStatus.INITIALIZING);
      const userAccount = await getUserAccount();
      updateAccountState(state => {
        state.userAccount = userAccount ?? null;
      });

      // Pause for a moment
      await new Promise(resolve => setTimeout(resolve, 1000));
      // Need to set the modal status to SUCCESS
      setModalStatus(FlavorModalStatus.SUCCESS);
      // The the child component will handle closing the modal
      return;
    } catch (error) {
      // If there is an error, set the modal status to ERROR
      console.error(
        'An error occurred while updating flavor or reinitializing user account:',
        error
      );
      setModalStatus(FlavorModalStatus.ERROR);
      return;
    }
  };

  if (!subscription) return null;

  if (loading) {
    if (!flavor) {
      setLoading(false);
      return null;
    }
    return (
      <>
        <FlavorModal
          onClose={handleClose}
          status={modalStatus}
          subscription={subscription}
          flavor={flavor}
        />        
      </>
    );
  }

  if (
    flavors.length > 1 && 
    subscription.status === SubscriptionStatus.active
    && !subscription.cancellationRequestedAt
  ) {
    return (
      <>
        <Accordian
          expanded={false}
          summary={
            <>
              <Box display={'flex'} flexGrow={1} justifyContent={'space-between'} py={'1rem'}>
                <Stack spacing={1}>
                  <Typography variant="body1">Flavor</Typography>
                  <Typography variant="body2" color="#ABABAB">
                    {subscription?.preferences?.gummyFlavor &&
                      formatGummyFlavor(subscription.preferences.gummyFlavor as GummyFlavor)}
                  </Typography>
                </Stack>
                <Box>
                  <Button
                    variant="outlined"
                    size={'small'}
                    sx={{ background: 'white', color: '#191919', px: '1rem' }}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </>
          }
        >
          <Stack spacing={1} px={'1rem'}>
            {flavors.length > 1 && subscription.status === SubscriptionStatus.active && (
              <>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="body2">Select a new flavor</Typography>
                    <Typography variant="subtitle2">
                    All flavors are sugar-free, gluten-free, and plant-based.
                    </Typography>
                  </Box>
                  <Box display="flex" flexWrap="wrap">
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={flavor}
                        onChange={handleChange}
                      >
                        {flavors.map((flavor, index) => (
                          <FormControlLabel
                            value={flavor}
                            control={<Radio />}
                            slotProps={{
                              typography: { variant: 'subtitle1' },
                            }}
                            label={formatGummyFlavor(flavor as GummyFlavor)}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box pb={'2rem'}>
                    <Button variant="contained" sx={{ px: '2rem' }} onClick={handleUpdateFlavor}>
                      Update Flavor
                    </Button>
                  </Box>
                </Stack>
              </>
            )}
          </Stack>
        </Accordian>
      </>
    );
  }

  return (
    <>
      <Stack 
      spacing={1} 
      // px={'1rem'} 
      py={'1.5rem'}>
        <Typography variant="body1">Flavor</Typography>
        <Typography variant="body2" color="#ABABAB">
          {subscription?.preferences?.gummyFlavor &&
            formatGummyFlavor(subscription.preferences.gummyFlavor as GummyFlavor)}
        </Typography>
      </Stack>
    </>
  );
};

export default SubscriptionFlavor;

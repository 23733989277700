export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
}

// export const formatCurrency = (
//   price: number,
//   locale: string = 'en-US',
//   currency: string = 'USD'
// ): string => {
//   return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(price);
// };

export const formatCurrency = (
  price: number,
  locale: string = 'en-US',
  currency: string = 'USD',
  precision: number = 2
): string => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  }).format(price);
};


export default formatCurrency;

/**********************************************************************
 * * UTIL - FORMAT GUMMY FLAVOR
 * --------------------------------------------------------------------
 * The GummyFlavor ENUM is mostly camelCase with some exceptions. This
 * function will format the GummyFlavor ENUM into a more human readable
 * format. It will also allow for custom formats to be defined for the
 * cases where the default formatting is not sufficient.
 * ******************************************************************/

/**********************************************************************
 * * IMPORT AND DEFINE TYPES
 * --------------------------------------------------------------------
 * ******************************************************************/
// Import GummyFlavor ENUM
// import { GummyFlavor } from '../types/API';
import { GummyFlavor } from 'src/API';
import { gummyFlavorLabel, labelFormat } from '../elo.config';

// Define the Formatting Options as String Literals
type FormatOption = 'AllLowerCase' | 'AllUpperCase' | 'TitleCase' | 'SentenceCase';
// Define custom format type
type CustomFormat = {
  value: GummyFlavor;
  output: string;
};

/**********************************************************************
 * * DEFINE CUSTOM FORMATS
 * --------------------------------------------------------------------
 * These customer formats will be used to override the default formatting
 * for specific GummyFlavor ENUM values.
 * TODO: Move this to a configuration file.
 * ******************************************************************/
// Define custom formats where necessary
const customFormats = gummyFlavorLabel;

/**
 * Formats a GummyFlavor enum value into a human-readable string according to the specified format option.
 * @param flavor - The GummyFlavor enum value to format.
 * @param formatOption - The format option to apply. Defaults to 'TitleCase'.
 * @returns The formatted string.
 */
export const formatGummyFlavor = (flavor: GummyFlavor, formatOption: FormatOption = labelFormat): string => {
  // Check if there is a custom format for this flavor
  const customFormat = customFormats.find(format => format.value === flavor);
  if (customFormat) {
    return customFormat.output;
  }

  // Insert a space before each capital letter in camelCase words, then lowercase everything
  const spaced = flavor?.replace(/([A-Z])/g, ' $1').toLowerCase();
  // Split the string into words
  const words = spaced?.split(' ');

  switch (formatOption) {
    case 'AllLowerCase':
      return spaced; // already in all lower case
    case 'AllUpperCase':
      return spaced.toUpperCase();
    case 'TitleCase':
      return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    case 'SentenceCase':
      return words?.map((word, index) => index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word).join(' ');
    default:
      return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }
};

export default formatGummyFlavor;

import React from 'react';
import styled, { useTheme } from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { ContentfulPageHeader } from 'types';
import { Image } from 'ui';

// ** ELO UI COMPONENTS
import { useCustomerData } from 'src/elo';

import { Button } from '@mui/material';
import { useEloStore } from '../../store/EloStore.hooks';
import { AppBar, Toolbar, Box, Hidden, Container, Typography } from '@mui/material';

// import { renderSocialLinks } from '../footer/Footer';
import { useScrollDirection } from '../../../utils/scroller';
import { navigate } from 'gatsby';
import { Login } from 'src/elo/ui/Account/components/loginButton';

export default function Header({
  responsiveLogo,
  pushdownBanner,
  menuItems,
  ctaButton,
  cta,
  socialMedia,
  supportEmail,
}: ContentfulPageHeader): JSX.Element {
  const scrollDir = useScrollDirection();
  const [isFirstRender, setIsFirstRender] = React.useState<boolean>(true);
  React.useEffect(() => {
    setIsFirstRender(false);
  }, []);
  const theme = useTheme();
  const {
    layout: { toggleCartWidget },
    store: { cart },
  } = useEloStore();

  // See if we can get the account data from the ELO Partner Library
  const { isActive } = useCustomerData();

  const hasItemsInCart = Object.values(cart.items).length >= 1;

  const [isMobileMenuShown, setMobileMenuShown] = React.useState<boolean>(false);

  const handleNavigation = () => {
    if (typeof window !== 'undefined') {
      window.open('https://apps.apple.com/us/app/luminary-vitamins/id6483369133', '_blank');
    }
  };

  React.useEffect(() => {
    const handleScroll = () => {
      const nav = document.getElementById('header');
      if (nav) {
        /**************************************************
         * FUTURE SELF AND OTHER DEVELOPERS
         * ------------------------------------------------
         * This is a hack to make the nav bar stick. I change
         * the injected class name from scrolling to scrolling-kill.
         *
         * To renable the nav to scroll away during scroll down, and re-appear on scroll up,
         * change the class name back to scrolling.
         * TODO: Set up the scrolling on mobile to make the nav bar disappear on scroll down, then reappear on scroll stop.
         * Not hard to do when you have time, but when you're on the clock and people care about demos and conversions, it's not a priority.
         **************************************************/
        if (scrollDir === 'down') {
          nav.classList.add('scrolling');
        } else {
          nav.classList.remove('scrolling');
        }

        if (scrollY >= 10) {
          nav.classList.add('scrolled');
        } else {
          nav.classList.remove('scrolled');
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    document.body.addEventListener('touchmove', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.body.removeEventListener('touchmove', handleScroll);
    };
  }, [scrollDir]);

  const toggleMenu = () => {
    setMobileMenuShown(!isMobileMenuShown);
  };

  const some = socialMedia && socialMedia[0] && socialMedia[0].links;

  const hasMenuItems = !isEmpty(menuItems);
  const hasCta = !!cta || !!ctaButton;

  return (
    <>
      <AppBar
        component={'nav'}
        position={'fixed'}
        sx={{
          paddingTop: '8px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0)', // Reduce shadow intensity
          justifyContent: 'center',
          backgroundImage: 'none',
          background: 'white',
          transition: 'top 0.3s ease-in-out',
          top: '0px',
        }}
      >
        <Container maxWidth={'xl'}>
          <Toolbar
            variant="dense"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mx: ['0px'],
              px: ['0px'],
              height: ['60px', '60px', '80px'],
            }}
          >
            <Box width={'150px'} onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
              <Image {...responsiveLogo.mobile} className="logo mobile" />
            </Box>
            <Box display={'flex'} gap={2} alignItems={'center'} justifyContent={'center'}>
              <Button variant="outlined" onClick={handleNavigation}>
                <Typography variant="body2" color={'primary'}>Get iOS app</Typography>                
              </Button>
              <Box
              mr={['1rem', '.47rem']}
              >
              <Login />
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
const CartButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin-right: 10px;
  display: none !important;
`;
const MobileMenu = styled.div(
  ({ theme }) => `
  display: flex;
  flex-flow: column;

  .links {
    display: flex;
    flex-flow: column;
    a {
      margin: 10px 0;
      color: ${theme.colors.eloBlack};
      font-size: 43px;
      line-height: 43px;
      font-family: "Everett Light";

    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 25px 20px 20px;
    margin-top: auto;
    display: flex;
    flex-flow: row;
    a, a > div {
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
    .support-email {
      font-size: 14px;
      line-height: 15.4px;
      letter-spacing: -1px;
      text-decoration: underline;
      color: ${theme.colors.eloDarkGrey};
      margin-right: auto;
    }
    a:not(.support-email) {
      text-align: right;
      > div {
        margin-right: 0;
        min-width: 20px;
      }
    }
  }
  `
);
const MobileMenuControls = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.colors.white};
  margin-top: 1rem;
  margin-bottom: 25px;

  ${theme.breakpoints.tablet} {
    display: none;
  }
`
);
const MobileMenuTitle = styled.h3(
  ({ theme }) => `
  font-family: "Everett Light";
  font-size: 29px;
  line-height: 32px;
  letter-spacing: -1px;
  color: ${theme.colors.eloBlack};
  margin: 0;
`
);
const CloseMenuButton = styled.button`
  border: none;
  color: black;
  background-color: transparent;
  svg {
    width: 18px;
    height: 18px;
  }
`;

const HeaderContainer = styled.header(
  ({ theme }) => `
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  max-height: 200px;
  background-color: transparent;
  &.scrolled {
    background-color: ${theme.colorScheme === 'dark' ? theme.colors.black : 'white'};
  }
  transform: translateY(0);
  transition: all 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);

  ${theme.breakpoints.mobile} {
    &.scrolling { 
      transform: translateY(-100px);
    }
    &.scrolled {
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    }
  }
  &.pdp-video, &.pdp-video .menu-container {
    background-color: white !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05) !important;
  }
  @media screen and (max-width: 1020px) {
    &.discount-ribbon, &.discount-ribbon .menu-container {
      background-color: white !important;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05) !important;
    }
  }

`
);

const LogoAndMenuContainer = styled.div(
  ({ theme }) => `
  padding-left: 25px;
  padding-right: 16px;
  height: 54px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  background-color: 'transparent';

  ${theme.breakpoints.tablet} {
    padding: 0 ${theme.padding.horizontal.tablet}px;
    height: 60px;
  }
  ${theme.breakpoints.desktop} {
    padding: 0 70px;
  }

  &:not(.menu) {
    &.cta {
      justify-content: flex-end;
    }
  }
`
);

const LogoContainer = styled.div(
  ({ theme }) => `
  div.mobile {
    display: block;
  }
  margin-left: 1rem;
  position: absolute;
  width: 100%;
  max-width: 150px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  z-index: 0;
  div:not(.mobile) {
    display: none;
  }
  ${theme.breakpoints.small} {    
    order: 2;
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: auto;
    position: static;
    div.mobile {
      display: none;
    }
    div:not(.mobile) {
      display: block;
    }
  }
`
);

const Menu = styled.div(
  ({ theme }) => `
  display: none;

  ${theme.breakpoints.small} {
    display: flex;
    order: 3;
    width: 100%;
    height: 100%;
    justify-content: center;
    flex: 1 1 auto;
    > .links {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 0;
      margin: 0;

      a.home {
        display: none;
      }
      
      a {
        font-size: 18px;
        height: 100%;
        flex: 0 1 auto;
        margin: 0 29px;
        list-style-type: none;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 3px solid transparent;
        color: ${theme.colors.eloBlack};
        text-decoration: none;
        :hover {
          border-bottom: 3px solid ${theme.colors.primary};
        }
      }
    }
  }
`
);

const MobileMenuButtonContainer = styled.div(
  ({ theme }) => `
  display: flex;
  order: 1;
  margin-right: 102px;
  ${theme.breakpoints.small} {
    display: none;
  }
`
);

const MobileMenuButton = styled.button(
  ({ theme }) => `
  border: none;
  background-color: transparent;
  padding: 0;
  z-index: 99;
`
);

const CTAButtonContainer = styled.div<{ hasItemsInCart: boolean }>(
  ({ theme, hasItemsInCart }) => `
  order: 5;
  display: flex;
  align-items: center;
  z-index: 99;
  #cart-button {
    svg {
      fill: ${hasItemsInCart ? theme.colors.eloBlack : ''}
    }
  }
  button {
    font-size: 11px;
    line-height: 15.2px !important;

    display: flex;
    justify-content: center;
    align-items: center;
    ${theme.breakpoints.mobile} {
      min-height: 34px !important;
      max-height: 34px !important;
      width: auto !important;      
      padding: 5px 12px 5.6px;
    }
    ${theme.breakpoints.small} {
      font-size: 15px;
      line-height: 21.6px !important;
      min-height: 35px !important;
      max-height: 35px !important;

      &:not(#cart-button){
        // width: 126px !important;
        padding: 11px 38px 11px;
      }
    }
  }
`
);

/********************************************************************************
 * ACTION: CHECKOUT
 * ----------------------------------------------------------------------------
 * This action is used to trigger a checkout using the user's credit card that
 * is on file with our payment processor.
 *
 * This should be automatic and it should not require a user to go to a checkout
 * and enter their credit card information.
 * *****************************************************************************/

/**********************************************************************
 * * GENERATE API CLIENT
 * ********************************************************************/
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

/**********************************************************************
 * * IMPORT GRAPHQL RESULT AND GRAPHQL OPTIONS TYPES
 * ********************************************************************/
import { startCheckout } from '../data/graphql/mutations';
// import { StartCheckoutInput, StartCheckoutMutation } from '../types/API';
import { StartCheckoutInput, StartCheckoutMutation } from 'src/API';
import { GraphQLResult, GraphQLOptions } from '@aws-amplify/api-graphql';

/******************************************************************************************
 * * CHECKOUT FUNCTION
 * ---------------------------------------------------------------------------------------
 * ***************************************************************************************/

interface CheckoutInput {
  productId: string;
  priceId: string;
  purchasableSubscriptionId: string;
  customerEmail: string;
  dontCancelExistingSubscriptions?: boolean;
}

const checkout = async ({
  productId,
  priceId,
  purchasableSubscriptionId,
  customerEmail,
  dontCancelExistingSubscriptions = true,
}:CheckoutInput) => {
  // 1. Build the mutation
  // 2. Call the mutation
  // 3. Handle the response

  /**********************************************************************
   * * BUILD CHECKOUT MUTATION
   * ********************************************************************/
  const buildMutation = (): GraphQLOptions => {
    const mutation = startCheckout;
    const authMode = 'userPool';
    const variables: { input: StartCheckoutInput } = {
      input: {
        customerEmail: customerEmail,
        directPurchase: true, // Set to false
        dontCancelExistingSubscriptions: dontCancelExistingSubscriptions,
        lineItems: [
            {
                productId,
                purchasableSubscriptionId,
                quantity: 1,
                priceId,
            }
        ]
      },
    };
    return {
      query: mutation,
      authMode,
      variables,
    };
  };

  /**********************************************************************
   * * START CHECKOUT
   * ********************************************************************/

  try {
    const query = buildMutation();
    const response = (await client.graphql(query)) as GraphQLResult<StartCheckoutMutation>;
    const data = response.data?.startCheckout
    if (data) {
      return {status: 200, data: data};
    } else {
      throw new Error('Failed to complete checkout, no data was returned');
    }

  } catch (error) {
    return {status: 500, data: error};
  }

};

export default checkout;

import React from 'react';
import { Box, Typography } from '@mui/material';

const Home = () => {
  return (
    <Box minHeight={'50vh'}>
      <Typography variant="h4" gutterBottom>Account Managmeent Home Panel</Typography>
      <Typography variant="body2">
        This is the home panel for the account management page.
      </Typography>
    </Box>
  );
};

export default Home;

/********************************************************************************
 * * ELO LIBRARY CONFIGURATION
 * ------------------------------------------------------------------------------
 * This file contains the configurationS for the ELO library.
 * TODO: Create overrides that can be passed in as an argument to the ELO library.
 * *****************************************************************************/

/********************************************************************************
 * * LOGIN REDIRECT
 * ------------------------------------------------------------------------------
 * When a user logs in, they are redirected to a predefined page. This configuration
 * allows for the login redirect to be defined.
 * *****************************************************************************/
export const loginRedirect = '/account';

/********************************************************************************
 * * GUMMY FLAVOR CUSTOM FORMAT
 * ------------------------------------------------------------------------------
 * Gummy flavors are formatted in a specific way. This configuration allows for
 * custom formats to be defined for specific GummyFlavor ENUM values.
 * 
 * Here is an example of a custom format:
 * { value: GummyFlavor.blackcurrant, output: 'Black Currant' },  
 * *****************************************************************************/
import { GummyFlavorCustomFormat } from "./types/config.types";
import { GummyFlavor, PregnancyStage } from "./types/API";

export const gummyFlavorLabel: GummyFlavorCustomFormat[] = [
    // { value: GummyFlavor.blackcurrant, output: 'Black Currant' },        
  ];



/********************************************************************************
 * * LABEL STYLE
 * ------------------------------------------------------------------------------
 * This configuration allows for the label style to be defined. The label style
 * is used to determine how the label for a specific ENUM value should be
 * formatted.
 * *****************************************************************************/
import { LabelFormat } from "./types/config.types";
export const labelFormat: LabelFormat = 'SentenceCase';

/********************************************************************************
 * * PREGNANCY STAGE
 * ------------------------------------------------------------------------------
 * This is a custom configuration for the pregnancy stage.
 * Map the pregnancy stage to a label that should be displayed.
 * *****************************************************************************/
export const pregnancyStage = [
  { stage: PregnancyStage.preconception, label: 'Preconception' },
  { stage: PregnancyStage.pregnant, label: 'Pregnant' },
  { stage: PregnancyStage.pregnantTrimester1, label: 'First Trimester'},
  { stage: PregnancyStage.pregnantTrimester2, label: 'Second Trimester'},
  { stage: PregnancyStage.pregnantTrimester3, label: 'Third Trimester'},
  { stage: PregnancyStage.postpartum, label: 'Postpartum'},
  { stage: PregnancyStage.lactating, label: 'Lactating'},
  { stage: PregnancyStage.postpartum, label: 'Postpartum'},
  { stage: PregnancyStage.lostPregnancy, label: 'Lost Pregnancy'},
  { stage: PregnancyStage.maleReproductiveHealth, label: 'Male Reproductive Health'}  
]

/********************************************************************************
 * * AVAILABLE FLAVORS
 * ------------------------------------------------------------------------------
 * This configuration allows for the available flavors to be defined.
 * *****************************************************************************/
export const availableFlavors = [
  { value: GummyFlavor.apple, label: 'Apple' },
  { value: GummyFlavor.peach, label: 'Peach' },
  { value: GummyFlavor.watermelon, label: 'Watermelon' },  
  { value: GummyFlavor.mixedBerry, label: 'Mixed Berry' },
]

/********************************************************************************
 * * PRODUCT IMAGES
 * ------------------------------------------------------------------------------
 * This configuration allows for the product images to be defined. The product
 * images are used to determine the image that should be displayed for a specific
 * product id. The format for this config is a key value pair where the key is
 * the product id and the value is the image url.
 * 
 * key: purchasableSubscriptionId
 * value: imageUrl
 * 
 * The key can be found in then nutrition app. This is the subscription id that
 * is assigned to the product there. It serves as the unique identifier for the
 * product.
 * 
 * The value is the url of the image that should be displayed for the product.
 * 
 * These can be set by the CMS so that you don't have to manually configure this
 * and then passed into the ELO library as an argument.
 * 
 * TODO: Create overrides that can be passed in as an argument to the ELO library.
 * *****************************************************************************/

export const productImages: Record<string, string> = {  
  'create':'https://cdn.sanity.io/images/a3su0y11/production/902634a64b74a5b21f45b53539c36eb13acc8621-640x721.webp',
  'form':'https://cdn.sanity.io/images/a3su0y11/production/9bbcffbd7165c11b4e3c967a1de06783a15c596e-640x721.webp',
  'flourish':'https://cdn.sanity.io/images/a3su0y11/production/8968cfe978890f022c234b256437198d32f77ef4-640x721.webp',
  'nurture':'https://cdn.sanity.io/images/a3su0y11/production/62d0a5e1fcef75138e58173c1491543d059be776-640x721.webp',
  'thrive':'https://cdn.sanity.io/images/a3su0y11/production/b2b3cc748d254761d4c817a13f4e3fa341e072f0-640x721.webp',
  'balance':'https://cdn.sanity.io/images/a3su0y11/production/05cd491ad416c9e85e0caf4c210f39c59e5d8e69-640x721.webp',
  'create-for-men':'https://cdn.sanity.io/images/a3su0y11/production/9208d7dc5b7f0a2a7abaadcc0893b94df9f1e8fd-640x721.webp',
  'thrive-for-men':'https://cdn.sanity.io/images/a3su0y11/production/9208d7dc5b7f0a2a7abaadcc0893b94df9f1e8fd-640x721.webp',
}

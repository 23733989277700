// Generic Re-Usabel Accordian

import React from 'react';
import { Accordion as MuiAccordion, Box } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { TypographyProps } from '@mui/material/Typography';

interface AccordianProps {
  children: React.ReactNode;
  // title: string;
  summary: React.ReactNode;
  expanded?: boolean;
  variant?: TypographyProps['variant'];
}

const Accordian = ({  
  children,
  expanded,  
  summary,
}: AccordianProps) => {
  return (
    <MuiAccordion
      component={Box} // present as a div
      defaultExpanded={expanded}
      disableGutters
      elevation={0} // Remove paper shadow
      sx={{
        backgroundColor: 'transparent', // Ensure transparent background
        '&:before': {
          display: 'none', // Remove default accordion divider line and gradient
        },
      }}
    >
      <AccordionSummary
        // expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          px: 0, // Remove horizontal padding
        }}
      >        
        {summary}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordian;

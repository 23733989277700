import React, { useState } from 'react';
import {
  Box,
  Stack,
  Typography,
  Button,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from '@mui/material';
import { SubscriptionStatus } from 'src/API';
import { cancelSubscription } from '../../../../actions';
import { UserSubscription } from '../../../../types/API';
import { refreshAccountStore } from '../../../../data';
import CancelOption from './CancelOption';
import PauseOption from './PauseOption';

interface CancelModalProps {
  openModal: (value: boolean) => void;
  setValue: (value: SubscriptionStatus | number | undefined | null) => void;
  subscriptionId: string;
  subscription: UserSubscription;
  value: any;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #AAA',
  boxShadow: 24,
  pt: 1,
  px: 2,
  pb: 2,
  borderRadius: 2,
};

const StatusModal = ({ openModal, setValue, subscriptionId, subscription, value }: CancelModalProps) => {

  return (
    <>
      <Box
        width={['94vw', '420px', '480px']}
        minHeight={['320px']}
        maxHeight={['94vh']}
        sx={{
          ...style,
          display: 'flex',
          flexDirection: 'column',
          // minHeight: '400px',
          overflow: 'auto', // This ensures that the content area can scroll if needed          
        }}
      >
        {value === SubscriptionStatus.canceled ? (
          <CancelOption
            openModal={openModal}
            subscriptionId={subscriptionId}
            subscription={subscription}
            value={value}
            setValue={setValue}
          />
        ) : (
          <PauseOption
            openModal={openModal}
            subscriptionId={subscriptionId}
            subscription={subscription}
            value={value}
            setValue={setValue}
          />
        )}
      </Box>
    </>
  );
};

export default StatusModal;

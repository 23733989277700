/********************************************************************************
 * UTIL: LOAD LINE ITEMS
 * ------------------------------------------------------------------------------
 * This utility function is used to load line items from the database into the
 * local state. This function is called when the app is first loaded. It will
 * load all line items from the database and store them in the zustand store.
 *
 * This function will initailly be called in the gatsby-browser.js file.
 * However, it should also be called whenever there's a need to refresh the
 * line items in the app. This could be if, for example, the data is somehow
 * lost and needs to be reloaded.
 * *******************************************************************************/

import { generateClient } from 'aws-amplify/api';
import { getLineItems } from 'src/graphql/queries';
import { GetLineItemsQuery } from 'src/API';
import { useStoreV2 } from 'src/store/StoreV2';

/********************************************************************************
 * IS AUTHENTICATED
 * ------------------------------------------------------------------------------
 * Import the isAuthenticated function from the ELO package to determine the
 * authentication mode that is necessary to use. The isAuthenticated function
 * simplifies the process of determining the authentication mode by checking the
 * state of the user.
 * *****************************************************************************/
import { isAuthenticated } from 'src/elo';

interface LoadLineItemsProps {
  affiliation?: string;
  referral_code?: string;
}

export const loadLineItems = async ({ affiliation, referral_code }: LoadLineItemsProps) => {
  const client = generateClient();

  const updateStore = useStoreV2.getState().updateState;

  try {
    updateStore(state => {
      state.loadingLineItems = true;
    });

    /********************************************************************************
     * CONDITIONAL AUTH MODE
     * ------------------------------------------------------------------------------
     * Set the auth mode approriately based on the user's authentication status.
     * This is necessary because the user's authentication status will determine
     * how the data is fetched from the database. If this is not done, then the
     * query will fail due to a permissions error.
     * *******************************************************************************/    
    const authMode = isAuthenticated() ? 'userPool' : ('iam' as 'userPool' | 'iam');

    /********************************************************************************
     * INPUT PARAMETERS
     * ------------------------------------------------------------------------------
     * The input params will be constructed based on any querystring parameters
     * that have been passed to the page.
     * *******************************************************************************/

    const response = (await client.graphql({
      query: getLineItems,
      authMode,
      variables: {
        input: {
          affiliation: affiliation,
          referralCode: referral_code,
        },
      },
    })) as { data: GetLineItemsQuery };

    const lineItems = response.data;

    updateStore(state => {
      state.lineItems = lineItems;
      state.loadingLineItems = false;
    });
  } catch (error) {
    console.error('Error fetching line items', error);
    updateStore(state => {
      state.loadingLineItems = false;
    });

    console.error('Error fetching line items', error);
  }
};

export default loadLineItems;

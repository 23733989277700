import React from 'react';
import { FormControlLabel, Radio, Typography, RadioGroup, Stack } from '@mui/material';
import { UserSubscription } from 'src/API';
import utilAddMonths from './utilAddMonths';

interface SetPauseOptionsProps {
  subscription: UserSubscription;
  value: number;
  setValue: (value: number) => void;
}

const SetPauseOptions = ({ subscription, value, setValue }: SetPauseOptionsProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value as any);
  };

  return (
    <>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        <Stack spacing={2}>
          <FormControlLabel
            value={1}
            control={<Radio />}
            label={
              <>
                <Typography variant="body2" sx={{ marginTop: '8px', fontWeight: 'bold' }}>
                  Delay for one month
                </Typography>
                <Typography variant="subtitle2" sx={{ marginTop: 1 }}>
                  Ships on {utilAddMonths(subscription.periodEndAt ?? '', 1)}
                </Typography>
              </>
            }
            sx={{ alignItems: 'flex-start' }} // Align items to start at the top
          />

          <FormControlLabel
            value={2}
            control={<Radio />}
            label={
              <>
                <Typography variant="body2" sx={{ marginTop: '8px', fontWeight: 'bold' }}>
                  Delay for two months
                </Typography>
                <Typography variant="subtitle2" sx={{ marginTop: 1 }}>
                  Ships on {utilAddMonths(subscription.periodEndAt ?? '', 2)}
                </Typography>
              </>
            }
            sx={{ alignItems: 'flex-start' }} // Align items to start at the top
          />

          <FormControlLabel
            value={3}
            control={<Radio />}
            label={
              <>
                <Typography variant="body2" sx={{ marginTop: '8px', fontWeight: 'bold' }}>
                  Delay for two months
                </Typography>
                <Typography variant="subtitle2" sx={{ marginTop: 1 }}>
                  Ships on {utilAddMonths(subscription.periodEndAt ?? '', 3)}
                </Typography>
              </>
            }
            sx={{ alignItems: 'flex-start' }} // Align items to start at the top
          />
        </Stack>
      </RadioGroup>
    </>
  );
};

export default SetPauseOptions;

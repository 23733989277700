import React, { useState } from 'react';
import { Box, Stack, Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { formatGummyFlavor } from '../../../../../utils';
import { GummyFlavor } from '../../../../../types/API';
import { useAccountStore } from '../../../../../store';

const SelectFlavor = () => {
  /********************************************************************************
   * * USE ACCOUNT STORE
   * ------------------------------------------------------------------------------
   * * NOTE:
   * The account store has two objects related to the user account data.
   * * userAccount
   * The current user data that is synced with the ELO cloud.
   *
   * * newUserData
   * The new user data that is being updated as the user navigates through the
   * account management tools. This data is then used to update the user's account
   * information in the ELO cloud once the user has confirmed the changes.
   * *****************************************************************************/
  const gummyFlavor = useAccountStore(state => state.userAccount?.preferences.gummyFlavor);
  const newGummyFlavor = useAccountStore(state => state.newUserData?.preferences.gummyFlavor);
  // const availableFlavors = useAccountStore(state => state.userConfiguration?.gummyFlavors ?? []);
  const updateNewUserData = useAccountStore(state => state.updateNewUserData);
  const lineItems = useAccountStore(state => state.lineItems);

  const [value, setValue] = useState<GummyFlavor | null>(gummyFlavor as GummyFlavor);

  // Get the available flavors for Form
  const getAvailableFlavors = () => {
    const item = lineItems?.find(item => item.purchasableSubscriptionSlug === 'form');
    return item?.flavors ?? [];
  }

  const availableFlavors = getAvailableFlavors();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFlavor = (event.target as HTMLInputElement).value as GummyFlavor;

    setValue(newFlavor);
    updateNewUserData(state => {
      state.preferences.gummyFlavor = newFlavor as GummyFlavor;
    });
  };

  return (
    <Stack
      width={'100%'}
      display={'flex'}
      alignContent={'center'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Box my={'.47rem'}>
        <Typography variant="h2">Try a new flavor?</Typography>
      </Box>
      <Box my={'.47rem'}>
        <Typography variant="caption">
          All flavors are gluten-free, plant-based, and have no added sugar
        </Typography>
      </Box>
      <Box my={'.47rem'} display={'flex'} width={'100%'}>
        <Stack spacing={2} width={'100%'}>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={newGummyFlavor || gummyFlavor}
            onChange={handleChange}
          >
            {availableFlavors.map((flavor, index) => (
              <FormControlLabel
                key={index}
                value={flavor}
                control={<Radio />}
                label={<Typography variant="body2">{formatGummyFlavor(flavor)}</Typography>}
              />
            ))}
          </RadioGroup>
        </Stack>
      </Box>
    </Stack>
  );
};

export default SelectFlavor;

/********************************************************************************
 * ACTION: UPDATE FLAVOR
 * ----------------------------------------------------------------------------
 * This action executes a mutation to update the flavor for a single
 * subscription.
 * *****************************************************************************/

/**********************************************************************
 * * GENERATE API CLIENT
 * ********************************************************************/
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

/**********************************************************************
 * * IMPORT GRAPHQL RESULT AND GRAPHQL OPTIONS TYPES
 * ********************************************************************/
import { updateSubscription } from 'src/graphql/mutations';
import { UpdateSubscriptionInput, UpdateSubscriptionResult } from 'src/API';
import { GraphQLResult, GraphQLOptions } from '@aws-amplify/api-graphql';

/******************************************************************************************
 * * IMPORT ACCOUNT STORE
 * ---------------------------------------------------------------------------------------
 * ***************************************************************************************/
import { useAccountStore } from '../store';

/******************************************************************************************
 * * UPDATE FLAVOR FUNCTION
 * ---------------------------------------------------------------------------------------
 * ***************************************************************************************/

const updateFlavor = async (input: UpdateSubscriptionInput) => {
    
    // * BUILD UPDATE FLAVOR MUTATION
    const buildMutation = (): GraphQLOptions => {
        const mutation = updateSubscription;
        const authMode = 'userPool';
        const variables: { input: UpdateSubscriptionInput } = {
        input,
        };
        return {
        query: mutation,
        authMode,
        variables,
        };
    };
    
    // * CALL THE MUTATION
    try {
        const mutation = buildMutation();
        const result = (await client.graphql(mutation)) as GraphQLResult<UpdateSubscriptionResult>;
    
        if (result.data) {
        return result;
        } else {
        throw new Error(result.errors?.[0].message);
        }
    } catch (error) {
        return error;
    }
};

export default updateFlavor;
